import React from 'react';

export const links = [
    {
      title: 'Home',
      links: [
        {
      //    name: 'ordersss',
          name: 'Home',
        //  icon: <AiOutlineShoppingCart />,
        },
      ],
    },
    {
      title: 'About',
      links: [
        {
      //    name: 'ordersss',
          name: 'Home',
        //  icon: <AiOutlineShoppingCart />,
        },
        {
          name: 'About',
         // icon: <IoMdContacts />,
        }, 
      ],
    },
    {
      title: 'ChatGpt',
      links: [
        {
      //    name: 'ordersss',
          name: 'Home',
        //  icon: <AiOutlineShoppingCart />,
        },
        {
          name: 'About',
         // icon: <IoMdContacts />,
        }, 
      ],
    }
];